import { createError, defineNuxtRouteMiddleware } from '#imports'

import { useSourcesStore } from '@/stores/sources'

export default defineNuxtRouteMiddleware((to) => {
  const sources = useSourcesStore()

  const source = sources.getById(String(to.params.id))

  if (source) {
    return
  }

  return createError({ statusCode: 404 })
})
